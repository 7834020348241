/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Prevent adjustments of font size after orientation changes in iOS. */
html {
  font-size: 100%;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Remove default padding, Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  padding: 0;
  list-style: none;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons, Remove the margin in Firefox and Safari, Remove the inheritance of text transform in Firefox.*/
input,
button,
textarea,
select {
  font: inherit;
  margin: 0;
  text-transform: none;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/** Remove the bottom border in Chrome 57-, Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari. */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline dotted; /* 2 */
}

/**Add the correct font weight in Chrome, Edge, and Safari. */
b,
strong {
  font-weight: bolder;
}

/** Remove the inner border and padding in Firefox.*/
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**Restore the focus styles unset by the previous rule.*/
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**Add the correct vertical alignment in Chrome, Firefox, and Opera.*/
progress {
  vertical-align: baseline;
}

/**Correct the cursor style of increment and decrement buttons in Chrome.*/
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**Correct the odd appearance in Chrome and Safari. Correct the outline style in Safari.*/
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[role="region"] {
  outline: none;
}

[data-reach-dialog-overlay] {
  z-index:9
}